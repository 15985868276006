import { DeviatingOpeningHoursContext } from "contexts/DeviatingOpeningHoursContext";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useRef, useState } from "react";
import Cookies from "universal-cookie";

import { checkWebPSupport } from "@utils/imageHelpers/webPHelper";
import { getIsCoveredByInsurance } from "@utils/layoutHelpers/layoutHelper";
import { getPersonalInfoByAudienceId } from "@utils/targetGroup/getPersonalInfo";
import { getRoleById, getSectorById } from "@utils/targetGroup/targetGroupHelper";
import { trackExternalLink } from "@utils/trackingHelpers/externalLinkHelper";
import { getBaseUrl } from "@utils/urlResolver";

import { CookieConsentCategory, useCookieConsent } from "hooks/useCookieConsent";
import useParamsToggler from "hooks/useParamToggler";
import { IFocusState, useFocusState } from "hooks/zustand/useFocusState";
import { PageInfo, usePageInfo } from "hooks/zustand/usePageInfo";
import { ISectorToggler, useSectorToggler } from "hooks/zustand/useSectorToggle";
import { DeviatingOpeningHours } from "types/DeviatingOpeningHours";

import PageFooter from "./PageFooter/PageFooter";
import PageHeader from "./PageHeader/PageHeader";
import { layoutScrollTracker, virtualPageviewTracker } from "./layoutTracker";

const cookie = new Cookies();

interface ILayout {
  children: ReactNode;
  is404: boolean;
  siteHeaderData: any;
  siteFooterData: any;
  preview: boolean;
  pageName: string;
  firstLevelPageName: string;
  secondLevelPageName: string;
  insuranceName: string;
  insuranceCoveredTargetGroups: string[];
  hideContactSectionInFooter: boolean;
  deviatingOpeningHours?: DeviatingOpeningHours[];
}

const Layout = ({
  children,
  is404,
  siteHeaderData,
  siteFooterData,
  preview,
  pageName,
  firstLevelPageName,
  secondLevelPageName,
  insuranceName,
  insuranceCoveredTargetGroups,
  hideContactSectionInFooter,
  deviatingOpeningHours = [],
}: ILayout): JSX.Element => {
  const necessaryCookieConsentGiven = useCookieConsent(CookieConsentCategory.necessary);
  const [referrer, setReferrer] = useState(null);
  const [previousPath, setPreviousPath] = useState<string>("");
  const setSelectedSector = useSectorToggler((state: ISectorToggler) => state.setSelectedSector);
  const selectedSector = useSectorToggler((state: ISectorToggler) => state.selectedSector);
  const isLoading = useSectorToggler((state: ISectorToggler) => state.isLoading);
  const overridePageTopFocus = useFocusState((state: IFocusState) => state.overridePageTopFocus);
  const setOverridePageTopFocus = useFocusState(
    (state: IFocusState) => state.setOverridePageTopFocus
  );
  const onPageLoadRef = useFocusState((state: IFocusState) => state.onPageLoadRef);
  const setOnPageLoadRef = useFocusState((state: IFocusState) => state.setOnPageLoadRef);
  const setPageInfo = usePageInfo((state: PageInfo) => state.setPageInfo);
  const pageInfo = usePageInfo((state: PageInfo) => state.pageInfo);
  const pageInfoLoading = usePageInfo((state: PageInfo) => state.pageInfoLoading);
  const router = useRouter();
  const tabStartRef = useRef(null);
  let scrollTracker = { 25: false, 50: false, 75: false, 100: false };
  const { isOpen } = useParamsToggler();

  useEffect(() => {
    const handleScrollTracking = () => {
      const pageHeight = document?.body?.scrollHeight;
      const scrollY = window?.scrollY;
      const innerHeight = window?.innerHeight;
      const percentage = Math.round(((scrollY + innerHeight) / pageHeight) * 100);

      if (percentage >= 25 && scrollTracker[25] === false) {
        scrollTracker[25] = true;
        layoutScrollTracker(25);
      }

      if (percentage >= 50 && scrollTracker[50] === false) {
        scrollTracker[50] = true;
        layoutScrollTracker(50);
      }

      if (percentage >= 75 && scrollTracker[75] === false) {
        scrollTracker[75] = true;
        layoutScrollTracker(75);
      }

      if (percentage >= 100 && scrollTracker[100] === false) {
        scrollTracker[100] = true;
        layoutScrollTracker(100);
        window.removeEventListener("scroll", handleScrollTracking);
      }
    };

    setTimeout(() => window.addEventListener("scroll", handleScrollTracking), 0);

    return () => {
      window.removeEventListener("scroll", handleScrollTracking);
    };
  }, [router.asPath]);

  useEffect(() => {
    if (isLoading) return;

    const origin = getBaseUrl();
    const externalLinks = document?.body?.querySelectorAll(
      `a:not([href*="${origin}"]):not([href^='/']):not([href^='#']):not([href*="tel:"]):not([href*="mailto:"]):not([href*="afa.imagevault"]):not([href*="anpdm.com"])`
    );

    for (const link of externalLinks) {
      if (!link.getAttribute("gaListener")) {
        link.addEventListener("click", trackExternalLink);
        link.setAttribute("gaListener", "true");
      }

      () => {
        link?.removeEventListener("click", trackExternalLink);
        link?.removeAttribute("gaListener");
      };
    }
  }, [router?.asPath, selectedSector, isLoading]);

  useEffect(() => {
    if (
      necessaryCookieConsentGiven &&
      !router?.asPath?.includes("#innehall") &&
      !router?.asPath?.includes("kriterier-for-digital-tillganglighet") &&
      !overridePageTopFocus &&
      !isOpen
    ) {
      tabStartRef?.current?.focus();
    }
    if (overridePageTopFocus && onPageLoadRef) {
      onPageLoadRef?.current?.focus();
    }
    setOverridePageTopFocus(false);
    setOnPageLoadRef(null);
  }, [router?.asPath, necessaryCookieConsentGiven]);

  useEffect(() => {
    if (!necessaryCookieConsentGiven || isLoading || pageInfoLoading) return;
    //  if (!shouldTrackSectorPageView(pageInfo?.pageRefferer, router.asPath)) return;

    const sector = getSectorById(selectedSector?.sector?.sectorId);
    const role = getRoleById(selectedSector?.role?.roleId);

    virtualPageviewTracker(
      role,
      sector,
      pageInfo?.firstLevelPageName,
      pageInfo?.secondLevelPageName,
      pageInfo?.pageRefferer,
      pageInfo?.pageName,
      pageInfo?.insuranceName,
      pageInfo?.isCoveredByInsurance
    );
  }, [necessaryCookieConsentGiven, isLoading, pageInfoLoading, pageInfo]);

  useEffect(() => {
    const onPathChangeEvent = new CustomEvent("onPathChangeEvent", {
      detail: {
        message: router.asPath,
      },
    });

    document.dispatchEvent(onPathChangeEvent);
  }, [router.asPath]);

  useEffect(() => {
    if (router?.asPath.includes("?openSector=") || referrer?.includes("?openSector=")) return;
    const audienceId = cookie.get("Personalize");
    if (!audienceId && router.asPath.startsWith("/forsakring")) {
      setSelectedSector(null);
    } else {
      setSelectedSector(getPersonalInfoByAudienceId(audienceId));
    }
    const isCoveredByInsurance = getIsCoveredByInsurance(audienceId, insuranceCoveredTargetGroups);

    setPageInfo({
      pageName: is404 ? "404" : pageName,
      firstLevelPageName: firstLevelPageName?.toLowerCase(),
      secondLevelPageName: secondLevelPageName?.toLowerCase(),
      pageRefferer: referrer ? referrer : typeof window !== "undefined" ? document?.referrer : "",
      isCoveredByInsurance: isCoveredByInsurance,
      insuranceName: insuranceName?.toLowerCase(),
    });
  }, [router.asPath]);

  useEffect(() => {
    setReferrer(window?.location?.href);
  }, [pageName]);

  useEffect(() => {
    checkWebPSupport("lossy", (feature, result) => {
      document.body.classList.add(`${result ? "webp" : "no-webp"}`);
    });

    // addEventListener("popstate", (event) => {});
    // onpopstate = (event) => {
    //   event.preventDefault();
    //   router.push(window.location.href, undefined, { scroll: false });
    // };

    // return () => {
    //   removeEventListener("popstate", (event) => {});
    // };
  }, [router]);

  // Open cookie consent modal on next page if first page
  // visited was /kakor, where it's not displayed.
  useEffect(() => {
    if (
      !necessaryCookieConsentGiven &&
      !router.asPath.endsWith("/kakor") &&
      previousPath.endsWith("/kakor")
    ) {
      global.cookieTractor?.openConsentSettings();
    }
    setPreviousPath(router.asPath);
  }, [router.asPath, previousPath, necessaryCookieConsentGiven]);

  return (
    <DeviatingOpeningHoursContext.Provider value={deviatingOpeningHours}>
      {siteHeaderData && (
        <PageHeader
          key="pageHeader"
          is404={is404}
          siteHeaderData={siteHeaderData}
          preview={preview}
          tabStartRef={tabStartRef}
        />
      )}
      {children && <main>{children}</main>}
      {siteFooterData && (
        <PageFooter
          key="pageFooter"
          showFooterContactArea={!hideContactSectionInFooter}
          siteFooterData={siteFooterData}
        />
      )}
    </DeviatingOpeningHoursContext.Provider>
  );
};

export default Layout;
