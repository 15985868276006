import clsx from "clsx";
import { useId, useRef, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Button from "@afa-shared/afa-components/dist/Buttons/Button";
import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Select from "@afa-shared/afa-components/dist/Inputs/Select";
import TextInput from "@afa-shared/afa-components/dist/Inputs/TextInput";
import Text from "@afa-shared/afa-components/dist/Text";
import { FormProps } from "@components/FormBlock";
import TrackedLink from "@components/TrackedLink";
import { LinkType } from "@utils/linkIconHelper";

import FormErrorFeedback from "../Feedback/FormErrorFeedback";
import FormSendFeedback from "../Feedback/FormSendFeedback";
import FormSuccessFeedback from "../Feedback/FormSuccessFeedback";
import FormValidationFeedback from "../Feedback/FormValidationFeedback";
import { emailMaxInputLength, generalMaxInputLength, phoneMaxInputLength } from "../formConstants";
import { scrollIntoView, validate } from "../formHelper";
import formStyles from "../forms.module.css";

export const TestpanelForm = ({ contentfulPreviewEntryId }: FormProps) => {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [errorSendMail, setErrorSendMail] = useState<boolean>(false);
  const [hasSingleError, setHasSingleError] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    firstname: { value: "", required: true },
    lastname: { value: "", required: true },
    email: { value: "", required: true },
    phone: { value: "", required: false, checkValidIfNotEmpty: true },
    state: { value: "", required: true },
    birth: { value: "", required: false, checkValidIfNotEmpty: true },
    role: { value: "", required: true },
    sektor: { value: "", required: true },
  });

  const [errors, setErrors] = useState({
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    state: null,
    birth: null,
    role: null,
    sektor: null,
  });

  const formRefs = {
    sendFeedback: useRef(null),
    errorInfobox: useRef(null),
    firstname: useRef(null),
    lastname: useRef(null),
    email: useRef(null),
    state: useRef(null),
    role: useRef(null),
    sektor: useRef(null),
    birth: useRef(null),
    phone: useRef(null),
  };

  const formIds = {
    firstname: useId(),
    lastname: useId(),
    email: useId(),
    phone: useId(),
    state: useId(),
    birth: useId(),
    role: useId(),
    sector: useId(),
    header: useId(),
  };

  const roles = [
    { id: 1, name: "Administratör", value: "Administratör" },
    { id: 1, name: "Chef/HR", value: "Chef/HR" },
    { id: 1, name: "Fackrepresentant", value: "Fackrepresentant" },
    { id: 1, name: "Forskare", value: "Forskare" },
    { id: 1, name: "Försäkringsinformatör", value: "Försäkringsinformatör" },
    { id: 1, name: "Privatperson", value: "Privatperson" },
    { id: 1, name: "Annat/Vill inte uppge", value: "Annat/Vill inte uppge" },
  ];

  const organizers = [
    { id: 1, name: "Privat sektor", value: "Privat sektor" },
    { id: 2, name: "Kooperativ sektor", value: "Kooperativ sektor" },
    {
      id: 3,
      name: "Kommuner och regioner eller kommunalt företag",
      value: "Kommuner och regioner eller kommunalt bolag",
    },
    {
      id: 4,
      name: "Svenska kyrkan",
      value: "Svenska kyrkan",
    },
    { id: 5, name: "Staten", value: "Statligt bolag" },
    { id: 6, name: "Egenföretagare", value: "Egenföretagare" },
    { id: 7, name: "Annat/vill inte uppge", value: "Annat/vill inte uppge" },
  ];

  const handleFormChange = (e?: any, key: string = null, termsChange: boolean = false) => {
    if (termsChange) {
      setFormState({
        ...formState,
        [key]: { ...formState[key], value: !formState[key].value },
      });
    } else {
      const { value } = e.target;
      setFormState({
        ...formState,
        [key]: { ...formState[key], value: value },
      });
    }

    setErrors({ ...errors, [key]: null });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setHasSingleError(false);
    const formErrors = validate(formState, errors);
    setErrors(formErrors);
    const foundErrors = Object.values(formErrors).filter((e) => e !== null).length;

    if (foundErrors) {
      if (foundErrors === 1) {
        setHasSingleError(true);
        const key = Object.keys(formErrors).find((key) => formErrors[key] !== null);
        scrollIntoView(formRefs[key]);
      } else {
        scrollIntoView(formRefs?.errorInfobox);
      }
    } else {
      let cleanFormState = {};
      for (const field in formState) {
        cleanFormState = { ...cleanFormState, [field]: formState[field].value };
      }
      setIsLoading(true);
      try {
        const res = await fetch("/api/testpanel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanFormState),
        });

        if (res.ok) {
          setDisplayForm(true);
          formRefs?.sendFeedback?.current?.focus();
          // formTracker("inskickat", "testpanel");
        } else {
          setErrorSendMail(true);
          setDisplayForm(true);
          // formTracker("felmeddelande", "testpanel");
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReCaptchaVerify = (token) => {
    if (!token) {
      return;
    }
    token && setIsValidToken(true);
  };

  const renderErrorInfoBox = () => (
    <FormValidationFeedback
      formErrors={errors}
      formRefs={formRefs}
      hasSingleError={hasSingleError}
    />
  );

  const renderFormFeedback = () => (
    <FormSuccessFeedback
      messageHeading="Din information har skickats"
      messageChildren={
        <Text variant="paragraph" removeMargin>
          Du får strax ett mejl från oss. Kontrollera att det inte fastnar i skräppostfiltret.
        </Text>
      }
      messageLink={
        <TrackedLink linkText="Så behandlar vi personuppgifter" url={"/personuppgifter"} />
      }
    />
  );
  const renderErrorMessage = () => <FormErrorFeedback />;

  const showFormFeedback: boolean = displayForm && !errorSendMail;

  const personuppgifterPath = "/personuppgifter";

  return (
    <Grid
      direction={"column"}
      className={"form-container"}
      data-contentful-entry-id={contentfulPreviewEntryId}
      data-contentful-field-id="name"
    >
      <GoogleReCaptcha onVerify={(token) => handleReCaptchaVerify(token)} />
      {
        <FormSendFeedback
          formSendRef={formRefs?.sendFeedback}
          formErrorFeedback={renderErrorMessage()}
          formSuccessFeedback={renderFormFeedback()}
          showSuccessFeedback={showFormFeedback}
          showErrorFeedback={errorSendMail}
        />
      }

      {!displayForm && (
        <div className={`${formStyles.formBackground} ${formStyles.formWrapper}`}>
          <div className={formStyles.formHeadingWrapper}>
            <Heading id={formIds?.header} variant="h2" removeMargin>
              Intresseanmälan
            </Heading>
            <Text variant="paragraph" removeMargin>
              * Obligatoriskt fält
            </Text>
          </div>
          {renderErrorInfoBox()}
          <form aria-labelledby={formIds?.header} noValidate className={formStyles.form}>
            <TextInput
              inputRef={formRefs?.firstname}
              label={"Ditt förnamn *"}
              id={formIds?.firstname}
              name="firstname"
              autoComplete="firstname"
              value={formState?.firstname?.value}
              type={"text"}
              maxLength={generalMaxInputLength}
              error={errors?.firstname?.input}
              fullWidth
              onChange={(e) => handleFormChange(e, "firstname")}
            />
            <TextInput
              inputRef={formRefs?.lastname}
              label={"Ditt efternamn *"}
              id={formIds?.lastname}
              name="lastname"
              autoComplete="lastname"
              value={formState?.lastname?.value}
              type={"text"}
              maxLength={generalMaxInputLength}
              error={errors?.lastname?.input}
              fullWidth
              onChange={(e) => handleFormChange(e, "lastname")}
            />
            <TextInput
              inputRef={formRefs?.email}
              label={"Din e-postadress (kontrollera stavningen) *"}
              id={formIds?.email}
              autoComplete="email"
              value={formState?.email?.value}
              type={"email"}
              maxLength={emailMaxInputLength}
              width={"100%"}
              error={errors?.email?.input}
              onChange={(e) => handleFormChange(e, "email")}
            />
            <TextInput
              inputRef={formRefs.phone}
              label={"Ditt telefonnummer"}
              id={formIds?.phone}
              autoComplete="phone"
              width={"100%"}
              maxLength={phoneMaxInputLength}
              value={formState?.phone?.value}
              type={"tel"}
              error={errors?.phone?.input}
              onChange={(e) => handleFormChange(e, "phone")}
            />
            <TextInput
              inputRef={formRefs?.state}
              label={"Bostadsort *"}
              id={formIds?.state}
              autoComplete="state"
              value={formState?.state?.value}
              type={"text"}
              maxLength={generalMaxInputLength}
              width={"100%"}
              error={errors?.state?.input}
              onChange={(e) => handleFormChange(e, "state")}
            />
            <TextInput
              inputRef={formRefs?.birth}
              label={"Ditt födelseår"}
              placeholder="ÅÅÅÅ"
              id={formIds?.birth}
              autoComplete="bday-year"
              width="100%"
              maxLength={4}
              value={formState?.birth?.value}
              type={"number"}
              error={errors?.birth?.input}
              onChange={(e) => handleFormChange(e, "birth")}
            />
            <Select
              inputRef={formRefs?.role}
              labelText={"Din roll *"}
              description="I vilken roll besöker du vår webbplats? Välj den beskrivning som passar bäst."
              options={roles}
              id={formIds?.role}
              selected={formState?.role?.value}
              placeholder="Välj roll"
              error={errors?.role?.input}
              onChange={(e) => handleFormChange(e, "role")}
              width={"100%"}
            />
            <Select
              inputRef={formRefs?.sektor}
              labelText={"Verksam i en organisation inom *"}
              options={organizers}
              id={formIds?.sector}
              selected={formState?.sektor?.value || ""}
              error={errors?.sektor?.input}
              onChange={(e) => handleFormChange(e, "sektor")}
              width={"100%"}
            />
            <TrackedLink
              url={personuppgifterPath}
              linkText={"Så behandlar vi personuppgifter"}
              linkType={LinkType.Internal}
              linkVariant={"link"}
              target={"_self"}
            />
            <Button
              type="submit"
              buttonType="primary"
              onClick={
                isValidToken
                  ? onSubmit
                  : (e) => {
                      e.preventDefault();
                    }
              }
              label={isLoading ? "" : "Skicka"}
              className={clsx(formStyles.submitButton, { [formStyles.spinner]: isLoading })}
              disabled={isLoading}
            />
          </form>
        </div>
      )}
    </Grid>
  );
};
export default TestpanelForm;
