import AccordionBlock from "@components/AccordionBlock";
import { BannerBlock } from "@components/BannerBlock";
import InsurancePickerBlock from "@components/ChatBoxComponents/InsurancePickerBlock";
import { ColorPaletteBlock } from "@components/ColorPaletteBlock";
import { ContactBlock } from "@components/ContactBlock";
import { ContactUsBlock } from "@components/ContactUsBlock";
import FAQBlock from "@components/FAQBlock";
import FAQListBlock from "@components/FAQListBlock";
import FileBlock from "@components/FileBlock";
import FileListBlock from "@components/FileListBlock";
import FormBlock from "@components/FormBlock";
import ImageBlock from "@components/ImageBlock";
import InformationBlock from "@components/InformationBlock";
import LinkBlock from "@components/LinkBlock";
import ListBlock from "@components/ListBlock";
import OrderBlock from "@components/OrderBlock";
import Page from "@components/Page";
import PaymentDaysBlock from "@components/PaymentDaysBlock";
import PressreleaseListBlock from "@components/PressreleaseListBlock";
import QuizBlock from "@components/QuizBlock";
import QuoteBlock from "@components/QuoteBlock";
import RestrictedRichTextBlock from "@components/RestrictedRichTextBlock";
import RichTextBlock from "@components/RichTextBlock";
import SectionBlock from "@components/SectionBlock";
import ShortCutsBlock from "@components/ShortCutsBlock";
import TableBlock from "@components/TableBlock";
import TeaserBlock from "@components/TeaserBlock";
import TeaserList from "@components/TeaserList";
import TimelineBlock from "@components/TimelineBlock";
import VideoBlock from "@components/VideoBlock";

import { ContentAreaItem } from "types/ContentAreaItem";
import { Context } from "types/context/Context";

import PersonalizationBlock from "../PersonalizationBlock";

interface IRender {
  contentAreaItem: ContentAreaItem;
  uniqueKey: any;
  context: Context;
  maxWidth: number;
}

const Render = ({ contentAreaItem, uniqueKey, context, maxWidth }: IRender): JSX.Element => {
  const typeName = Object.keys(contentAreaItem)[0];
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (typeName?.toLowerCase()) {
    case "insurancepickerblock":
      return (
        <InsurancePickerBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "faq":
      return (
        <FAQBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "accordionblock":
      return (
        <AccordionBlock
          blockData={contentAreaItem[typeName]}
          context={context}
          uniqueKey={uniqueKey}
          maxWidth={maxWidth}
        />
      );
    case "personalizationblock":
      return (
        <PersonalizationBlock
          blockData={contentAreaItem[typeName]}
          context={context}
          uniqueKey={uniqueKey}
          maxWidth={maxWidth}
        />
      );
    case "sectionblock":
      return (
        <SectionBlock
          blockData={contentAreaItem[typeName]}
          context={context}
          uniqueKey={uniqueKey}
          maxWidth={maxWidth}
        />
      );
    case "page":
      return (
        <Page
          blockData={contentAreaItem[typeName]}
          context={context}
          uniqueKey={uniqueKey}
          maxWidth={maxWidth}
        />
      );
    case "teaserblock":
      return (
        <TeaserBlock
          blockData={contentAreaItem[typeName]}
          context={context}
          uniqueKey={uniqueKey}
          maxWidth={maxWidth}
        />
      );
    case "videoblock":
      return (
        <VideoBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "fileblock":
      return (
        <FileBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "tableblock":
      return (
        <TableBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "richtextblock":
      return <RichTextBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} />;
    case "informationblock":
      return (
        <InformationBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "timelineblock":
      return (
        <TimelineBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "contactblock":
      return (
        <ContactBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "quizblock":
      return <QuizBlock blockData={contentAreaItem[typeName]} context={context} />;
    case "quoteblock":
      return (
        <QuoteBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "link":
      return <LinkBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} />;
    case "restrictedrichtextblock":
      return (
        <RestrictedRichTextBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "shortcutsblock":
      return (
        <ShortCutsBlock
          blockData={contentAreaItem[typeName]}
          context={context}
          maxWidth={maxWidth}
        />
      );
    case "listblock":
      return (
        <ListBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "pressreleaselistblock":
      return (
        <PressreleaseListBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "faqlistblock":
      return (
        <FAQListBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "formblock":
      return (
        <FormBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} context={context} />
      );
    case "imageblock":
      return (
        <ImageBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
          maxWidth={maxWidth}
        />
      );
    case "orderblock":
      return <OrderBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} />;
    case "filelistblock":
      return (
        <FileListBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "colorpaletteblock":
      return <ColorPaletteBlock blockData={contentAreaItem[typeName]} uniqueKey={uniqueKey} />;
    case "teaserlistblock":
      return <TeaserList blockData={contentAreaItem[typeName]} context={context} />;
    case "paymentdaysblock":
      return (
        <PaymentDaysBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "bannerblock":
      return (
        <BannerBlock
          blockData={contentAreaItem[typeName]}
          uniqueKey={uniqueKey}
          context={context}
        />
      );
    case "contactusblock":
      return <ContactUsBlock blockData={contentAreaItem[typeName]} context={context} />;
    default:
      return <></>;
  }
};

export default Render;
