import { DeviatingOpeningHoursContext } from "contexts/DeviatingOpeningHoursContext";
import { useContext } from "react";

import Grid from "@afa-shared/afa-components/dist/Grid";
import Heading from "@afa-shared/afa-components/dist/Heading";
import Table from "@afa-shared/afa-components/dist/Table";
import { getDateFromDateString } from "@utils/dateHelpers/getDateFromDateString";
import { getShortDate } from "@utils/dateHelpers/getShortDate";

import { Context } from "types/context/Context";

import { TableBlockType_tableBlock } from "./queries/contentfulTypes/TableBlockType";

export function formatOpeningHours(startTime: string | null, endTime: string | null) {
  const isMidnight = (time: string | null) => time === "00:00" || !time;

  if (!isMidnight(startTime) && isMidnight(endTime)) {
    return `från klockan ${startTime}`;
  }
  if (isMidnight(startTime) && !isMidnight(endTime)) {
    return `fram till klockan ${endTime}`;
  }
  if (!isMidnight(startTime) && !isMidnight(endTime)) {
    return `klockan ${startTime}-${endTime}`;
  }
  return "";
}

interface ITableBlockProps {
  blockData: TableBlockType_tableBlock;
  uniqueKey?: string;
  context?: Context;
  className?: string;
}

function DeviatingOpeningHoursTableBlock({
  blockData: tableBlock,
  context,
  className,
}: ITableBlockProps) {
  const deviatingOpeningHours = useContext(DeviatingOpeningHoursContext);

  const openingHoursRows = deviatingOpeningHours.map((d) => {
    const shortDate = getShortDate(getDateFromDateString(d.date));
    // const date = `${d.nameOfDay ? d.nameOfDay + " (" + shortDate + ")" : shortDate}`;
    const formattedOpeningHours = formatOpeningHours(d.startTime, d.endTime);
    const dateWithTime = `${shortDate} ${formattedOpeningHours}`;
    return [d.nameOfDay, dateWithTime];
  });

  return (
    <Grid
      container
      className={className}
      data-blockname="tabell"
      data-contentful-entry-id={tableBlock.sys.id}
      data-contentful-field-id="name"
    >
      {tableBlock?.heading && (
        <Grid margin="0 0 1rem">
          <Heading
            variant={context?.heading?.parentLevel}
            removeMargin
            children={tableBlock?.heading}
          />
        </Grid>
      )}
      <Table tableHeadings={["Stängt", ""]} tableRows={openingHoursRows} />
    </Grid>
  );
}

export const TableBlock = ({ blockData: tableBlock, context, className }: ITableBlockProps) => {
  if (tableBlock.source === "Avvikande öppettider") {
    return (
      <DeviatingOpeningHoursTableBlock
        blockData={tableBlock}
        context={context}
        className={className}
      />
    );
  }

  const tableHeadings = tableBlock.table.tableData?.slice(0)[0];
  const tableRows = tableBlock.table.tableData?.slice(1);

  // 1-based in Contentful admin app for ease of use for editors, need to convert to 0-based here
  const rightAlignedColumns = tableBlock.rightAlignedColumns?.map((x) => parseInt(x) - 1);

  return (
    <Grid
      container
      className={className}
      data-blockname="tabell"
      data-contentful-entry-id={tableBlock.sys.id}
      data-contentful-field-id="name"
    >
      {tableBlock?.heading && (
        <Grid margin="0 0 1rem">
          <Heading
            variant={context?.heading?.parentLevel}
            removeMargin
            children={tableBlock?.heading}
          />
        </Grid>
      )}
      <Table
        tableHeadings={tableHeadings?.length && tableHeadings}
        tableRows={tableRows?.length && tableRows}
        rightAlignColumns={rightAlignedColumns}
      />
    </Grid>
  );
};

export default TableBlock;
