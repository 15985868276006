import Link from "next/link";
import { useId } from "react";

import ContactArea from "@afa-shared/afa-components/dist/ContactArea";
import Footer from "@afa-shared/afa-components/dist/Footer";
import FooterLogo from "@afa-shared/afa-components/dist/FooterLogo";
import Grid from "@afa-shared/afa-components/dist/Grid";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import { LinkType, getInternalOrExternalIcon } from "@utils/linkIconHelper";
import { pixelsToRem } from "@utils/sizeHelper";

import { SimpleLinkData } from "types/SimpleLinkData";

import { footerLinksTracker } from "./FooterTracker";
import pageFooterStyles from "./PageFooter.module.css";

interface IFooterSettings {
  siteFooterData: any;
  showFooterContactArea: boolean;
}

const PageFooter = ({ siteFooterData, showFooterContactArea }: IFooterSettings): JSX.Element => {
  if (!siteFooterData) return <></>;

  const {
    columnOneHeader,
    columnOneLinksCollection,
    columnTwoHeader,
    columnTwoLinksCollection,
    columnThreeHeader,
    columnThreeLinksCollection,
    columnFourHeader,
    columnFourLinksCollection,
    contactHeading,
    contactText,
    orgNumber,
    socialMediaText,
    facebook,
    instagram,
    linkedIn,
    youtube,
    twitter,
    bottomLinksCollection,
    bottomText,
  } = siteFooterData;
  // const [toggleZoom, setToggleZoom] = useState<boolean>(false);

  const sectionId = useId();
  const getColumnData = (columnLinks, columnHeader) => {
    const columnLinkList = (
      <ul className={pageFooterStyles.list}>
        {columnLinks?.map((item: SimpleLinkData, i: number) => {
          if (item?.url && item?.text)
            return (
              <li key={i} className={pageFooterStyles.listItem}>
                <Link
                  href={item?.url}
                  prefetch={false}
                  onClick={() => footerLinksTracker(item?.text, columnHeader)}
                >
                  {item?.text}
                </Link>
              </li>
            );
          return null;
        })}
      </ul>
    );

    return {
      header: columnHeader,
      children: columnLinkList,
    };
  };

  const getBottomSection = (bottomLinkCollection) => {
    const bottomLinks = bottomLinkCollection?.map((item: SimpleLinkData, i: number) => {
      if (item?.url && item?.text)
        return (
          <li key={i}>
            <Link href={item?.url} prefetch={false}>
              {item?.text}
            </Link>
          </li>
        );
      return null;
    });

    //Append link for cookie consent settings
    bottomLinks.push(
      <li key="cookie-consent-settings">
        <button
          type="button"
          className={`${pageFooterStyles.cookieSettings} cookie-consent-settings`}
        >
          Mina inställningar för kakor
        </button>
      </li>
    );

    return {
      children: bottomLinks,
    };
  };

  //uncomment code for future toggle features

  // useEffect(() => {
  //   toggleZoom ? document.body.classList.add("use-zoom") : document.body.classList.remove("use-zoom");
  // }, [toggleZoom]);

  const socialMedia = {
    facebook: facebook,
    instagram: instagram,
    youtube: youtube,
    linkedIn: linkedIn,
    twitter: twitter,
    label: socialMediaText,
  };
  const columnOne = getColumnData(columnOneLinksCollection, columnOneHeader);
  const columnTwo = getColumnData(columnTwoLinksCollection, columnTwoHeader);
  const columnThree = getColumnData(columnThreeLinksCollection, columnThreeHeader);
  const columnFour = getColumnData(columnFourLinksCollection, columnFourHeader);
  const bottomLinks = getBottomSection(bottomLinksCollection);

  const box1 = () => (
    <div>
      <p>Öppet vardagar 8–17</p>
      <p>
        <a href="tel:+46771880099">0771-88 00 99</a>
      </p>
    </div>
  );
  const box2 = () => (
    <div>
      <p>Afa Försäkring</p>
      <p>106 27 Stockholm</p>
    </div>
  );
  const linkType = getInternalOrExternalIcon("https://goo.gl/maps/vWFVPAs3bHHcRVLWA", null, null);
  const box3 = () => (
    <div className={pageFooterStyles.contentBox}>
      <p>Klara Södra kyrkogata 18</p>
      <p>
        Stockholm &nbsp;
        <TrackedLink
          linkText="Visa karta"
          ariaLabel="Länk till google maps för att visa karta."
          url="https://goo.gl/maps/vWFVPAs3bHHcRVLWA"
          linkType={linkType}
          linkVariant={"link"}
          hideIcon={false}
          className={pageFooterStyles.contentBoxLink}
        />
      </p>
    </div>
  );

  return (
    <footer data-blockname="footer" style={{ marginTop: "2.5rem" }}>
      <img
        className="print-logo"
        src={"/images/icons/logo-black.svg"}
        style={{ display: "none" }}
      />
      {showFooterContactArea && (
        <Grid
          className={`${pageFooterStyles.contactAreaWrapper} ${pageFooterStyles.section}`}
          container
          justifyContent="center"
          alignItems="center"
          backgroundColor={"#c8ebfa"}
          id={sectionId}
          variant={"section"}
        >
          <Grid
            container
            maxWidth={pixelsToRem(1240)}
            backgroundColor={"#c8ebfa"}
            className={pageFooterStyles.container}
          >
            <ContactArea
              dataTestId="contact-area-block"
              imageUrl="/images/contact.svg"
              imageAltTxt="Man som pratar i telefon"
              children={
                contactText && (
                  <RichTextRenderer json={contactText?.json} links={contactText?.links} />
                )
              }
              heading={contactHeading}
              headingVariant={"h2"}
              contactBoxOneHeading={"Ring oss"}
              contactBoxTwoHeading={"Skicka post"}
              contactBoxThreeHeading={"Vårt kontor"}
              contactBoxOneText={box1()}
              contactBoxTwoText={box2()}
              contactBoxThreeText={box3()}
            />
          </Grid>
        </Grid>
      )}
      <Footer
        key="footer-component"
        columnOne={columnOne}
        columnTwo={columnTwo}
        columnThree={columnThree}
        columnFour={columnFour}
        socialMedia={socialMedia}
        links={bottomLinks}
        orgDescription={bottomText}
        orgNumber={orgNumber && <RichTextRenderer json={orgNumber?.json} />}
        footerLogo={
          <Link href={"/"} prefetch={false}>
            <div className={pageFooterStyles.screenReaderWrapper}>
              Afa Försäkring - Gå till startsidan
            </div>
            <FooterLogo imgUrl={"/images/icons/logo.svg"} />
          </Link>
        }
        // onToggle={handleZoomToggle}
        // isToggled={toggleZoom}
        // toggleLabel={"Växla zoom"}
        className={pageFooterStyles.pageFooter}
      />
    </footer>
  );
};

export default PageFooter;
