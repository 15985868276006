/* eslint-disable sonarjs/cognitive-complexity */
import { DeviatingOpeningHoursContext } from "contexts/DeviatingOpeningHoursContext";
import { useContext } from "react";

import { Heading, Icon, Message, Text } from "@afa-shared/afa-components";
import { contactTracker } from "@components/ContactBlock/contactTracker";
import RichTextRenderer from "@components/RichTextRenderer";
import TrackedLink from "@components/TrackedLink";
import { getNowDate } from "@utils/dateHelpers/getNowDate";
import { setLocalTimezone } from "@utils/dateHelpers/setLocalTimezone";

import { DeviatingOpeningHours } from "types/DeviatingOpeningHours";
import { ContactUsBlockContext } from "types/context/ContactUsBlockContext";
import { Context } from "types/context/Context";

import styles from "./contactUs.module.scss";
import { ContactUsBlockType_contactUsBlock } from "./queries/contentfulTypes/ContactUsBlockType";

type ContactUsBlockProps = {
  blockData?: ContactUsBlockType_contactUsBlock;
  context?: Context;
};

function Section({
  icon,
  heading,
  text,
  allowLinkIcon,
  context,
  hideOpeningHoursLink,
}: {
  icon?: string;
  heading?: string;
  text?: { json: any; links: any };
  allowLinkIcon?: boolean;
  context?: Context;
  contactTracker?: (value?: any) => void;
  hideOpeningHoursLink: boolean;
}) {
  const ContactUsContext: ContactUsBlockContext = {
    allowLinkIcon: allowLinkIcon,
    phoneTracking: contactTracker,
  };
  context = {
    ...context,
    contactUsBlock: ContactUsContext,
  };

  return (
    <div className={styles.section}>
      {icon && (
        <div className={styles.iconContainer}>
          <Icon size="sm" name={icon as any} />
        </div>
      )}
      <div className={styles.dataContainer}>
        {heading && (
          <Heading variant="h4" removeMargin className={styles.sectionHeading}>
            {heading}
          </Heading>
        )}
        {text && <RichTextRenderer json={text?.json} links={text?.links} context={context} />}
        {!hideOpeningHoursLink && (
          <TrackedLink
            linkText={"Avvikande öppettider"}
            url={"/kundtjanst/avvikande-oppettider"}
            hideIcon={true}
          />
        )}
      </div>
    </div>
  );
}

export function getDeviatingOpeningHoursState(
  deviatingOpeningHours: DeviatingOpeningHours[],
  now: Date
): "soon" | "today" | null {
  const dates = deviatingOpeningHours.map((deviatingOpeningHours) => {
    const date = new Date(deviatingOpeningHours.date);
    if (deviatingOpeningHours.startTime) {
      const [hours, minutes] = deviatingOpeningHours.startTime.split(":");
      date.setHours(parseInt(hours));
      date.setMinutes(parseInt(minutes));
    }
    return date;
  });
  dates.sort((a, b) => a.getTime() - b.getTime());

  const nearestUpcomingDate = dates.find(
    (date) =>
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() && date.getMonth() > now.getMonth()) ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() === now.getMonth() &&
        date.getDate() >= now.getDate())
  );

  if (!nearestUpcomingDate) {
    return null;
  }
  if (
    nearestUpcomingDate.getFullYear() === now.getFullYear() &&
    nearestUpcomingDate.getMonth() === now.getMonth() &&
    nearestUpcomingDate.getDate() === now.getDate()
  ) {
    return "today";
  }
  const threeDaysInMilliseconds = 1000 * 60 * 60 * 24 * 3;
  if (nearestUpcomingDate.getTime() - now.getTime() <= threeDaysInMilliseconds) {
    return "soon";
  }
  return null;
}

function MessageComponent({
  blockData,
  deviatingOpeningHoursState,
  showOverrideMessage,
}: {
  blockData: ContactUsBlockType_contactUsBlock;
  deviatingOpeningHoursState: ReturnType<typeof getDeviatingOpeningHoursState>;
  showOverrideMessage: boolean;
}) {
  let heading = "";
  if (showOverrideMessage) {
    heading = blockData.deviatingOpeningHoursHeading;
  }
  if (!heading && deviatingOpeningHoursState === "today") {
    heading = "Idag har vi stängt";
  } else if (!heading && deviatingOpeningHoursState === "soon") {
    heading = "Avvikande öppettider";
  }

  return (
    <Message heading={heading} type="info">
      {showOverrideMessage && (
        <Text
          variant={"paragraph"}
          children={blockData.deviatingOpeningHoursMessage}
          className={styles.messageText}
        />
      )}
      <TrackedLink
        linkText={"Se våra öppettider"}
        url={"/kundtjanst/avvikande-oppettider"}
        hideIcon={true}
      />
    </Message>
  );
}

export function ContactUsBlock({ blockData, context }: ContactUsBlockProps) {
  const deviatingOpeningHours = useContext(DeviatingOpeningHoursContext);
  const nowDate = getNowDate();

  const showOverrideMessage =
    setLocalTimezone(blockData.deviatingOpeningHoursStartDate) < nowDate &&
    setLocalTimezone(blockData.deviatingOpeningHoursEndDate) > nowDate &&
    blockData.deviatingOpeningHoursMessage !== "";

  const deviatingOpeningHoursState = getDeviatingOpeningHoursState(deviatingOpeningHours, nowDate);

  const showMessage =
    showOverrideMessage ||
    (blockData.showDeviatingOpeningHours &&
      (deviatingOpeningHoursState === "soon" || deviatingOpeningHoursState === "today"));

  return (
    <div
      className={styles.container}
      data-contentful-entry-id={blockData.sys.id}
      data-contentful-field-id="name"
      data-blockname="kontaktaossblock"
    >
      {blockData.heading && (
        <Heading variant="h3" className={styles.heading}>
          {blockData.heading}
        </Heading>
      )}
      {(blockData.emailHeading || blockData.emailText) && (
        <Section
          icon="email"
          heading={blockData.emailHeading}
          text={blockData.emailText}
          context={context}
          contactTracker={() => contactTracker("epost")}
          hideOpeningHoursLink={true}
        />
      )}
      {(blockData.phoneHeading || blockData.phoneText) && (
        <Section
          icon="phone_square"
          heading={blockData.phoneHeading}
          text={blockData.phoneText}
          context={context}
          contactTracker={() => contactTracker("telefon")}
          hideOpeningHoursLink={true}
        />
      )}
      {(blockData.phoneHoursHeading || blockData.phoneHoursText) && (
        <>
          <Section
            icon="watch_square"
            heading={blockData.phoneHoursHeading}
            text={blockData.phoneHoursText}
            hideOpeningHoursLink={showMessage}
          />
          {showMessage && (
            <MessageComponent
              blockData={blockData}
              deviatingOpeningHoursState={deviatingOpeningHoursState}
              showOverrideMessage={showOverrideMessage}
            />
          )}
        </>
      )}
      {(blockData.postHeading || blockData.postText) && (
        <Section
          icon="mail_square"
          heading={blockData.postHeading}
          text={blockData.postText}
          hideOpeningHoursLink={true}
        />
      )}
      {(blockData.visitorHeading || blockData.visitorText) && (
        <Section
          icon="map_square"
          heading={blockData.visitorHeading}
          text={blockData.visitorText}
          hideOpeningHoursLink={true}
        />
      )}
      {(blockData.visitorHoursHeading || blockData.visitorHoursText) && (
        <Section
          icon="watch_square"
          heading={blockData.visitorHoursHeading}
          text={blockData.visitorHoursText}
          hideOpeningHoursLink={true}
        />
      )}
      {blockData.richText && (
        <Section text={blockData.richText} allowLinkIcon={true} hideOpeningHoursLink={true} />
      )}
    </div>
  );
}
